import {
  faAngry,
  faArrowRight,
  faAward,
  faBomb,
  faBook,
  faBriefcaseMedical,
  faBug,
  faChalkboardTeacher,
  faChartArea,
  faChartBar,
  faCheckSquare,
  faCircleNotch,
  faCode,
  faCogs,
  faComment,
  faComments,
  faCommentDots,
  faCompass,
  faCompressAlt,
  faCrosshairs,
  faDollarSign,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFileDownload,
  faFileExport,
  faFileMedicalAlt,
  faFilePdf,
  faFileUpload,
  faFirstAid,
  faFish,
  faFrown,
  faGlobe,
  faGlobeAmericas,
  faGraduationCap,
  faIdCardAlt,
  faInfo,
  faInfoCircle,
  faLightbulb,
  faMedal,
  faMicroscope,
  faMinusSquare,
  faMobileAlt,
  faPhoneAlt,
  faPhoneVolume,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRetweet,
  faSchool,
  faSearch,
  faServer,
  faShieldVirus,
  faSkullCrossbones,
  faSmileWink,
  faSpider,
  faTable,
  faTachometerAlt,
  faTasks,
  faThumbsUp,
  faUnlock,
  faUnlockAlt,
  faUserSecret,
  faUserShield,
  faUserTie,
  faUserPlus,
  faShieldHalved,
  faFileWaveform,
  faCloud,
  faFileLines,
  faWandMagicSparkles,
  faCreditCard,
  faCreditCardAlt,
  faCheckDouble
} from "@fortawesome/free-solid-svg-icons";

import {faFile as faFilesR, faFileExcel, faFilePdf as faFilePdfRegular} from "@fortawesome/free-regular-svg-icons";
import {faSearchengin, faVuejs} from "@fortawesome/free-brands-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";

library.add(faMinusSquare);
library.add(faPlus);
library.add(faUserSecret);
library.add(faBug);
library.add(faEye);
library.add(faFileCode);
library.add(faCheckSquare);
library.add(faInfoCircle);
library.add(faInfo);
library.add(faSearch);
library.add(faTasks);
library.add(faCrosshairs);
library.add(faExclamationTriangle);
library.add(faFilesR);
library.add(faFile);
library.add(faFileAlt);
library.add(faFileArchive);
library.add(faFileCode);
library.add(faFileCsv);
library.add(faFileExport);
library.add(faFilePdf);
library.add(faFileDownload);
library.add(faFileUpload);
library.add(faTachometerAlt);
library.add(faTable);
library.add(faFish);
library.add(faFirstAid);
library.add(faComment);
library.add(faSkullCrossbones);
library.add(faCircleNotch);
library.add(faFilePdfRegular);
library.add(faSpider);
library.add(faChartArea);
library.add(faThumbsUp);
library.add(faBomb);
library.add(faMedal);
library.add(faLightbulb);
library.add(faDollarSign);
library.add(faServer);
library.add(faCommentDots);
library.add(faMobileAlt);
library.add(faQuestion);
library.add(faEnvelope);
library.add(faArrowRight);
library.add(faPhoneAlt);
library.add(faSmileWink);
library.add(faBriefcaseMedical);
library.add(faFrown);
library.add(faAngry);
library.add(faCompressAlt);
library.add(faChartBar);
library.add(faBook);
library.add(faGraduationCap);
library.add(faAward);
library.add(faUserTie);
library.add(faSchool);
library.add(faPhoneVolume);
library.add(faMicroscope);
library.add(faUserShield);
library.add(faCompass);
library.add(faIdCardAlt);
library.add(faFileMedicalAlt);
library.add(faShieldVirus);
library.add(faRetweet);
library.add(faSearchengin);
library.add(faCode);
library.add(faCogs);
library.add(faQuestionCircle);
library.add(faUnlock);
library.add(faUnlockAlt);
library.add(faGlobe);
library.add(faGlobeAmericas);
library.add(faEyeSlash);
library.add(faVuejs);
library.add(faFileExcel);
library.add(faChalkboardTeacher);
library.add(faComments);
library.add(faUserPlus);
library.add(faShieldHalved);
library.add(faFileWaveform);
library.add(faCloud);
library.add(faFileLines);
library.add(faWandMagicSparkles);
library.add(faCreditCard);
library.add(faCreditCardAlt);
library.add(faCheckDouble);
