/**
 * USAGE:
 *
 * Simple:
 * $t("general.name")
 *
 * Composed:
 * $t("composed.version", { version: "2.0" })
 */

export default {
  notification_alert: `Mer 2-ott-2024 dalle 17:30 alle 20:30 sarà effettuato uno stop della piattaforma per manutenzione; i task ancora attivi saranno interrotti.`,
  expired_license: "Expired license or invalid for service!",
  general: {
    name: `Name`,
    surname: `Surname`,
    email: `Email`,
    description: `Description`,
    phone: `Phone`,
    company: `Company`,
    company_url: `URL`,
    incident_type: `Incident type`,
    business_name: `Business name`,
    company_name: `Company name`,
    project_name: `Project name`,
    address: `Address`,
    add: `Add`,
    save: `Save`,
    next: `Next`,
    back: `Back`,
    abort: `Abort`,
    dismiss: `Dismiss`,
    close: `Close`,
    Engine: `Engine | Engines`,
    Report: `Report | Reports`,
    Target: `Target | Targets`,
    run_test: `Run test`,
    edit: `Edit`,
    show_reports: `Show reports`,
    make_new_test: `Make new test`,
    add_new: `Add new`,
    target_info: `Target Info`,
    scan_type: `Type`,
    delete: `Delete`,
    confirmation_required: `Confirmation required`,
    No: `No`,
    Additional_info: `Additional Info`,
    global_search: `Global Search`,
    date: `Date`,
    status: `Status`,
    view: `View`,
    no_reports: `There is no report`,
    no_pending_reports: `There is no pending report`,
    Pending_reports: `Pending reports`,
    all_severities: `All Severities`,
    critical_severity: `Critical Severity`,
    high_severity: `High Severity`,
    medium_severity: `Medium Severity`,
    low_severity: `Low Severity`,
    none_severity: `None Severity`,
    informational_severity: `Informational Severity`,
    ip_address: `IP Address`,
    total_potential_vulnerabilities: `Total potential vulnerabilities`,
    compromised_emails_others: `Compromised emails Domain specific / Others`,
    total_breach: `Compromised Emails`,
    total_breach_source: `Breach Sources`,
    total_availability_impact: `Total availability impact`,
    total_confidentiality_impact: `Total confidentiality impact`,
    total_integrity_impact: `Total integrity impact`,
    confidentiality: `Confidentiality`,
    availability: `Availability`,
    integrity: `Integrity`,
    subdomain: `Subdomain | Subdomains`,
    detail: `Detail | Details`,
    potential_vulnerabilities: `Potential vulnerabilities`,
    port: `Port | Ports`,
    critical: `Critical`,
    high: `High`,
    medium: `Medium`,
    low: `Low`,
    informational: `Informational`,
    none: `None`,
    ok: `OK`,
    ip_list: `IP list`,
    technologies: `Technologies`,
    info: `Info`,
    error: `Error`,
    message: `Message`,
    start_scan_at: `Start scan at`,
    end_scan_at: `End scan at`,
    vulnerabilities: `Vulnerabilities`,
    severity: `Severity`,
    total: `Total`,
    risk: `Risk`,
    technology_risk: `Technology risk`,
    human_risk: "Human Risk",
    gdpr_risk: `GDPR Risk`,
    breach_source: `Severity of Breaches`,
    started: `Started`,
    test_started: `Test correctly started`,
    scan_light: `Light Scan`,
    scan_full: `Full Scan`,
    contact_us: `Contact us`,
    created_at: `Created at`,
    download_brochure: "Download brochure",
    upgrade: `Upgrade`,
    free: `Free`,
    send: `Send`,
    scan_date: `Scan date`,
    services: `Services`,
    value: `Value`,
    choose_file: "Choose file",
    delete_file: "Delete file",
    warning: "Warning!",
    title: "Title",
    resource_name: "Resource name",
    record_id: "Record ID",
    language: "Language",
    issued_for: "Issued for",
    issuer: "Issuer",
    reported_source: "Reported source",
    malicious_activity: "Malicious activity",
    botnet: "Botnet",
    botnets: "Botnets",
    ports: "Ports",
    engine_description: "Service",
    details: "Details",
    snippet: `Snippet`,
    higher_cvss: "Higher CVSS",
    registered: "Registered",
    unregistered: "Unregistered",
    actions: "Actions",
    requests_come_from: "All requests will arrive from the following IP: <strong>{ip}</strong>",
    accuracy: "Accuracy",
    services_detection: "Services detection",
    probes_detection: "Probes detection",
    raw_data: "Raw data",
    no_alive_targets: "No targets up",
    download_progress: "Download",
    botnets_count: "Botnets count",
    findings: `Findings`,
    groups: `Groups`,
    sources: `Sources`,
    go_on: "Go on",
    count: "Count",
    enable: "Enable/Disable",
    policy_privacy_link: `https://www.swascan.com/privacy-policy/`,
    ransomware_attack_index: `Ransomware Attack Index`,
    non_repudiation: `Non Repudiation`,
    access_control: `Access Control`,
    other: `Other`,
    yes: "Yes",
    no: "No",
    no_data_available: `No data available`,
    common_name: `Server`,
    occurrence: `Num.`,
    start: `Start`,
    success_message: `Operation completed successfully!`,
    under_attack: 'Under Attack',
    settings: `Settings`,
  },
  nscan: {
    address_range: `Address range`,
    start_address: `Start address`,
    end_address: `End address`,
  },
  composed: {
    version: (context) => `Platform version ${context.named("version")}`,
  },
  pages: {
    account: {
      account_type: "Account type",
      account_type_is: "#[ENGINE_DESCR] account type is",
      contact: "Get in touch directly with us or one of our Partner to upgrade your account",
      contact_our_partner: "Contact our Partner",
    },
    dashboard: {
      total_scans: `Total scan`,
      scans_done: `Used scan`,
      remaining_scans: `Remaining scan`,
      subscription_expiration: `Subscription expire date`,
      scans: `Scans`,
      usage: `Usage`,
      license_type: `License type`,
    },
    targets: {
      table_head: `List of Targets grouped by Engine`,
      table_head_engine: (context) => `List of Targets for ${context.named("engine")}`,
      add_new_target: `Add new target`,
      edit_target: `Edit target`,
      delete_question: `Are you sure you want to delete this target?`,
      stop_question: `Are you sure you want to stop this task?`,
      confirm_body: `Are you sure you want to start this test?`,
      processing: `Processing...`,
      target_saved: `Target saved`,
      target_extra_saved: `Extra data of target saved`,
      ipv4_ipv6_only: "Target can only contains ipv4 and url or ipv6",
      mixed_ip_error: "Target can only contains private or public ip. You can't mix them into a single target.",
      errors: {
        target_save: `Error saving target`,
        target_extra_save: `Error saving target extra date`,
        target_not_allowed: `You are not allowed to create new target for this service`,
      },
      new_test: {
        title: "Network Scan",
        select_scan_profile: "Select the scan profile",
        nscan_full: "Full Scan",
        nscan_medium: "Fast Scan",
        nscan_lite: "Lite Scan",
        nscan_log4shell: "Log4shell",
        rapid_scan: "Full Scan",
        general_scan: "Medium Scan",
        port_scan: "Port Discovery",
        service_scan: "Service Discovery",
        base_scan: "Light Scan",
        general_port_service_scan: "General Port and Service Discovery",
        host_discovery_scan: "Host Discovery",
        network_discovery_scan: "Network Discovery",
        indemnity: "Site scanning: indemnity",
        note: `(a) You represent and warrant that You have full right, power, and
                    authority to consent to have the Service scan for malware or other vulnerabilities
                    of the Site identified to Tinexta Cyber for scanning, whether electronically or by any
                    other means, whether during initial Registration or thereafter. You will indemnify
                    and hold harmless Tinexta Cyber, its Authorized Resellers, partners and sponsors, and their
                    officers, directors, employees and agents from and against any third-party claims,
                    suits, liabilities, losses, damages, judgments, awards, fines, penalties, costs and
                    expenses (including reasonable attorneys’ fees) incurred by or levied against the
                    same resulting from or based on Your use of or inability to use the Service, including 
                    ny claim resulting from Your breach of this Section 7.<br>(b) You also acknowledge and
                    agree that the scanning of such Site may expose malware and other vulnerabilities and
                    in some circumstances could result in the disruption of services at such Site. Due to
                    the nature of scanning, You acknowledge and agree that the Service involves substantial
                    risk and as a consequence, You assume the risk for all damages, losses and expenses
                    resulting from Your use of the Service.`,
        scan_full_and_fast: `Full and fast`,
        scan_full_and_fast_ultimate: `Full and fast ultimate`,
        scan_full_and_very_deep: `Full and very deep`,
        scan_full_and_very_deep_ultimate: `Full and very deep ultimate`,
      },
      new_webtest: {
        title: "WebApp Scan",
        scan_strength: "Scan Strength",
        scanner_alert_threshold: "Scanner Alert Threshold",
        authentication_method_name: "Authentication Method",
        default: "Default",
        low: "Low",
        medium: "Medium",
        high: "High",
        off: "Off",
        insane: "Insane",
        select_scan_strength: "Select Strength",
        select_scanner_alert_threshold: "Select Threshold",
        no_auth: "No authentication",
        form_auth: "Form based authentication",
        select_auth: "Select type of authentication",
        login_url: "URL login in page",
        login_parameter_name: "User parameter name",
        password_parameter_name: "Password parameter name",
        username: "Username",
        password: "Password",
        logged_in_indicator: "Logged in indicator",
        logged_out_indicator: "Logged out indicator",
        regex: "Regex",
        scanned_urls: "Scanned URLs",
        authenticated: "Authenticated scan"
      },
      new_cti_test: {
        note: `(a) You represent and warrant that you have the legitimacy, and/or full right and/or authorization and/or authority and/or delegation 
              to conduct the activity on the analysis domains, using different techniques (for example: SIGINT, OSINT, HUMINT and subsequent analyses) 
              in order to characterize the possible IT threats.<br><br>
              (b) The information provided by our Cyber Threat Intelligence platform is provided on an "as is" and "as available" basis. 
              We therefore do not guarantee the accuracy, reliability or completeness of the information.<br><br>
              (c) You further acknowledge and agree that the Service subscribed to, the aggregate information collected and made available is 
              strictly confidential. You shall not disclose or misuse them. In this regard, you declare yourself aware that the 
              confidentiality of the information is your responsibility.<br><br>
              (d) You agree not to use our Service for any purpose that is illegal or prohibited by the laws of your country or our 
              Acceptable Use Policy.<br><br>
              (e) In no event shall we or our suppliers be liable for any damages, direct or indirect, incidental, special or consequential, 
              arising out of your misuse or inability to use our Service or your infringement of any third party right.<br><br>
              (f) Tinexta Cyber reserves the right to modify or interrupt the service at any time without notice in case of improper or 
              incorrect use by the user.`
      },
      delete_related_reports: `Do you want to delete all target's related reports?<br /><br />
<strong>Warning:</strong> pending and running test will not be deleted. You must delete manually when they will terminate.`,
    },
    main: {
      last_ten_reports: `Last 10 reports`,
    },
    report: {
      cve_details: `CVE Details`,
      service: `Service | Services`,
      potential_gdpr: `Potential GDPR`,
      country_name: `Country`,
      organization: `Organization`,
      isp: `ISP`,
      hostname: `Hostname | Hostnames`,
      asn: `ASN`,
      cve: `CVE`,
      similarity: `Domain Similarity`,
      epss: `EPSS`,
      cvss: `CVSS`,
      cvssv2: `CVSSV2`,
      cvssv3: `CVSSV3`,
      impact: `Impact`,
      severity: `Severity`,
      description: `Description`,
      breaches: `Breaches`,
      fullname: `Fullname`,
      year: `Year`,
      features: `Features`,
      total: `Total`,
      protocol: `Protocol`,
      product: `Product`,
      version: `Version`,
      subdomains: `Subdomains`,
      features_list: {
        "Browser user agent details": `Browser user agent details`,
        "Email addresses": "Email addresses",
        Genders: "Genders",
        "IP addresses": "IP addresses",
        "IP Addresses": "IP addresses",
        Names: "Names",
        Passwords: "Passwords",
        "Phone numbers": "Phone numbers",
        "Spoken languages": "Spoken languages",
        "Time zones": "Time zones",
        "Website activity": "Website activity",
        "Dates of birth": "Dates of birth",
        "Physical addresses": "Physical addresses",
        "Geographic locations": "Geographic locations",
        "Private messages": "Private messages",
        Usernames: "Usernames",
        Purchases: "Purchases",
        "Bank account numbers": "Bank account numbers",
        "Customer feedback": "Customer feedback",
        "Financial transactions": "Financial transactions",
        "Government issued IDs": "Government issued IDs",
        "Marital statuses": "Marital statuses",
        PINs: "PINs",
        "Security questions and answers": "Security questions and answers",
        "Social media profiles": "Social media profiles",
        "Credit card CVV": "Credit card CVV",
        "Partial credit card data": "Partial credit card data",
        "Biometric data": "Biometric data",
        "Family members names": "Family members names",
        "Job titles": "Job titles",
        "Passport numbers": "Passport numbers",
        "Physical attributes": "Physical attributes",
        "Beauty ratings": "Beauty ratings",
        "Car ownership statuses": "Car ownership statuses",
        "Drinking habits": "Drinking habits",
        "Education levels": "Education levels",
        "Home ownership statuses": "Home ownership statuses",
        "Income levels": "Income levels",
        "Personal descriptions": "Personal descriptions",
        "Personal interests": "Personal interests",
        "Sexual orientations": "Sexual orientations",
        "Smoking habits": "Smoking habits",
        "Recovery email addresses": "Recovery email addresses",
        Employers: "Employers",
        "Social connections": "Social connections",
        "Instant messenger identities": "Instant messenger identities",
        Ethnicities: "Ethnicities",
        "Credit status information": "Credit status information",
        "Historical passwords": "Historical passwords",
        "Auth tokens": "Auth tokens",
        "Device information": "Device information",
        Salutations: "Salutations",
        "Email messages": "Email messages",
        Bios: "Bios",
        "Homepage URLs": "Homepage URLs",
        "Credit cards": "Credit cards",
        "Support tickets": "Support tickets",
        "Sexual fetishes": "Sexual fetishes",
        "Astrological signs": "Astrological signs",
        "Drug habits": "Drug habits",
        "Fitness levels": "Fitness levels",
        "Parenting plans": "Parenting plans",
        "Political views": "Political views",
        "Relationship statuses": "Relationship statuses",
        Religions: "Religions",
        "Travel habits": "Travel habits",
        "Work habits": "Work habits",
        Avatars: "Avatars",
        "Email Addresses": "Email Addresses",
        "Physical Addresses": "Physical Addresses",
        "Payment histories": "Payment histories",
        "Reward program balances": "Reward program balances",
        "Account balances": "Account balances",
        "Birth dates": "Birth dates",
        "User website URLs": "User website URLs",
        "Health insurance information": "Health insurance information",
        "Personal health data": "Personal health data",
        "Deceased statuses": "Deceased statuses",
        Nationalities: "Nationalities",
        "Family structure": "Family structure",
        "Financial investments": "Financial investments",
        "Net worths": "Net worths",
        Occupations: "Occupations",
        "Device usage tracking data": "Device usage tracking data",
        "Cryptocurrency wallet hashes": "Cryptocurrency wallet hashes",
        "Mnemonic phrases": "Mnemonic phrases",
        "Geographic Location": "Geographic Location",
        "School grades (class levels)": "School grades (class levels)",
        "Address book contacts": "Address book contacts",
        "Apps installed on devices": "Apps installed on devices",
        "Cellular network names": "Cellular network names",
        "IMEI numbers": "IMEI numbers",
        "IMSI numbers": "IMSI numbers",
        "Profile photos": "Profile photos",
        "SMS messages": "SMS messages",
        "Professional skills": "Professional skills",
        "Years of professional experience": "Years of professional experience",
        "Customer interactions": "Customer interactions",
        "MAC addresses": "MAC addresses",
        "Purchasing habits": "Purchasing habits",
        Ages: "Ages",
        "Taxation records": "Taxation records",
        "Chat logs": "Chat logs",
        "User statuses": "User statuses",
        "Survey results": "Survey results",
        "Age groups": "Age groups",
        "Payment methods": "Payment methods",
        "Geographical Locations": "Geographical Locations",
        "Company titles": "Company titles",
        "Family data": "Family data",
        "Social security numbers": "Social security numbers",
        "Employment statuses": "Employment statuses",
        "Career levels": "Career levels",
        "Buying preferences": "Buying preferences",
        "Charitable donations": "Charitable donations",
        "Political donations": "Political donations",
        "Eating habits": "Eating habits",
        "Years of birth": "Years of birth",
        Races: "Races",
        "Job applications": "Job applications",
        "Vehicle details": "Vehicle details",
        "Home loan information": "Home loan information",
        "Password hints": "Password hints",
        "Deceased date": "Deceased date",
        "Utility bills": "Utility bills",
        Nicknames: "Nicknames",
        "Audio recordings": "Audio recordings",
        "Browsing histories": "Browsing histories",
        Photos: "Photos",
        "Personal information": "Personal information",
        "Hashed Passwords": "Hashed Passwords",
        "Document Titles": "Document Titles",
      },
      total_ip_found: `Total ip found`,
      total_subdomains_found: `Total subdomains found`,
      chart_full_impact: `Confidentiality, Integrity and Availability Impact`,
      chart_potential_by_risk: `Potential vulnerability by risk`,
      chart_average_potential_vulnerabilities: `Average Potential Vulnerabilities for Subdomains`,
      average_total: `Total Average`,
      average_high: `High Average`,
      average_medium: `Medium Average`,
      average_low: `Low Average`,
      typosquatting: `Typosquatting`,
      domain: `Domain`,
      data: `Data`,
      dns_a: `DNS A`,
      dns_mx: `DNS MX`,
      dns_ns: `DNS NS`,
      open_ports: `Open ports`,
      os: `O.S.`,
      key: `Key`,
      value: `Value`,
      host: `Host | Hosts`,
      host_list: `Host list`,
      vulnerabilities_risk_distribution: `Vulnerability distribution by severity`,
      vulnerabilities_cia_impact: `Vulnerability distribution by GDPR impact`,
      family: `Family`,
      summary: `Summary`,
      access_vector: `Access Vector`,
      access_complexity: `Access Complexity`,
      authentication: `Authentication`,
      solution: `Solution`,
      local: `Local`,
      adjacent_network: `Adjacent Network`,
      network: `Network`,
      multiple: `Multiple`,
      single: `Single`,
      none: `None`,
      cia_impact: `Vulnerability distribution by GDPR impact and related severity`,
      average_vulnerabilities_per_host: `Average vulnerability per host`,
      creation_date: `Creation date`,
      breaches_chart: `Breaches Chart`,
      breaches_by_risk: `Breaches by risk`,
      upgrade_to_full: `To view a full report upgrade your license and run a new scan!`,
      port_status: {
        title: `State`,
        open: `Open`,
        closed: `Closed`,
      },
      top_vulnerable_host: `Most vulnerable hosts`,
      vulnerabilities_trend: `Vulnerability trend`,
      executive_report_ppt: "Executive Report PPT",
      vulnerabilities_report: "Vulnerabilities Report",
      executive_report: "Executive Report",
      remediation_plan_eng: "Remediation Plan ENG",
      remediation_plan_ita: "Remediation Plan ITA",
      full_report: "Full Report",
      dark_web_threat_history: "Dark Web Threat History",
      domain_name: "Domain name",
      name_server: "Name server",
      mail_server: "Mail server",
      cti: {
        data_breaches: "Data Breaches",
        comprimised_credentials_email: "Compromised Credentials and Emails",
        data_breaches_description: `Data Breach detected (direct and/or third party) and compromised emails. Depending on the case you can provide: Password used, Password Hash, Record without password, but of which there is trace in the Deep and Dark Web`,
        breach_risks: `Breach Severities`,
        breaches_message: `It allows detecting the presence of Data Breaches - direct or from third parties - and any compromised accounts.<br>
                You can view complete information for breached account, impact of the breach, date and origin of the breach`,
        breaches_chart_message: `This graph represents the severity of the Data Breaches detected for Target <strong>{domain}</strong>. 
                Severity is based on the sensitivity of the exfiltrated data and the publication date of the Breach.`,
        ip_threat_intelligence: "IP Threat Intelligence",
        ip_threat_intelligence_sub: "Identified Malicious Activity on your Ip",
        ip_threat_intelligence_description: `The "reputation" of a public IP address is similar to its "network history", i.e. the history of malicious actions that have been carried out, or have passed through, or have had as their final destination, called the IP address. Civil and criminal legal liability, information theft, industrial espionage, etc.`,
        dark_web: "Dark Web",
        dark_web_sub: "What's hidden from Google's eye",
        dark_web_description: `Analysis of instances on the so-called Dark Web, such as threat actors (cyber criminals, typically) on cyber crime forums who have spoken about the <strong>{domain}</strong>. This level of information gathering can improve Domain Security, IP Security, Brand Reputation. Another aspect is also a Focus on spread confidential or personal data, scams and fraud to be perpetrated against <strong>{domain}</strong>`,
        dark_web_message: `It allows to obtain an overview of the level of attention to analyzed domain, 
                typically by Threat Actors.<br>
                Threat Actors who have mentioned or discussed the Organization (understood as brands, domains, IP addresses 
                or names of senior executives) can be found on cybercrime forums`,
        botnet: "Botnet Activities",
        botnet_sub: "Data exfiltrated from compromised computers and belonging to a Botnet",
        botnet_description: `We Detects Botnet Activity on your network. A botnet is a network of computers infected with malware, spyware, key loggers, etc. The "bot hardener" (the botnet manager) can use its botnet network to launch attacks (e.g. DDoS), or instruct it to steal credentials (e-banking, corporate intranet, civil and criminal legal liability, information theft, industrial espionage, etc.)`,
        certificate_message: `It list all SSL certificates currently issued for the domain being scanned.<br>
                You can monitor, and consequently proactively manage, the SSL certificates 
                associated with your domains; in this way it will be possible to guarantee a correct 
                configuration, detecting any anomalies or improper / unauthorized use of the certificates and 
                mitigating the risks deriving from threats related to SSL certificates.`,
        ip_address_threats: `Threats on IP addresses`,
        ip_address_threats_message: `This Results represent the <strong>Any Threats Identified by one of Public Sensors or Cyber Security Communities</strong>. 
                Gating all the data from Hundreds of sources are quite hard which we provide an Intelligent system to Update and Monitor 
                any Threat on specific IP related to domain <strong>{domain}</strong>.`,
        ip_history_data: `Ip History Data`,
        ip_history_data_message: `The IP history derives from OSINT and infrastructural Information Gathering activities. The evidence collected up to the last 10 years is provided.`,
        ip_malicious_data: `Ip Malicious Data`,
        ip_malicious_data_message: `List of Malicious Data of selected IP.`,
        botnets_title: `Botnets`,
        botnets_message: `Data represents assets (computers, servers, IoT devices, smartphones) compromised by botnets. 
                A botnet is a network of devices infected with malware, spyware, keyloggers, etc. Assets directly attributable to the 
                Organization are shown, but also personal or public devices, used to access company systems in remote working mode or 
                in perimeters outside the Company.`,
        attack_surface_title: `Attack Surface`,
        attack_surface_message: `Attack Surface is combination of vulnerabilities, interfaces and accesses that can be used by an attacker to compromise a system or a network.
    Attack Surface for a Company includes all open ports, used protocols, installed software`,
        risks_by_categories: "Risks by Categories",
        certificate_transparency: "Certificate Transparency",
        certificates: "Certificates",
        malwares: "Malwares IoC",
        malwares_message: "Below are last active IoCs related to main families of malware retrieved in botnet section",
        registered_phishing: `Phishing`,
        registered_phishing_sub: `Count of registered domaind obtained with typosquatting that can be used for phishing`,
        registered_phishing_description: `List of registered typosquatting domains that can be used to deceive final users to obtain sensitive informations or make phishing activity. Typosquatting is a social engineering technique that takes advantage of a typo by typing an URL in the browser to redirect user toward a not-legitimate site`,
        unregistered_phishing: `Potential Phishing`,
        unregistered_phishing_sub: `Count of not (yet) registered domaind obtained with typosquatting that can be used in future for phishing`,
        unregistered_phishing_description: `List of not (yet) registered typosquatting domains that can be used to deceive final users to obtain sensitive informations or make phishing activity. Typosquatting is a social engineering technique that takes advantage of a typo by typing an URL in the browser to redirect user toward a not-legitimate site`,
        services: `Services`,
        services_sub: `Exposed Services indicate the Attack Surface exposed to potential attackers.`,
        phishing_message: `Possible registered typosquatting domains are listed. These could be used to deceive end users and obtain 
                  sensitive information or conduct phishing activities. Typosquatting is a social engineering tactic that exploits 
                  typos when entering a URL into the browser.`,
      },
      delete_question: `Are you sure you want to delete this report?`,
      stop_question: `Are you sure you want to stop this task?`,
      references: "References",
      ip_down_list: `IP Down List`,
      hosts_down_list: `Hosts Down List`,
      webapp: {
        risk_chart: `Risk of Exploitation Based on Severity (%)`,
        vulnerabilities_chart: `Vulnerabilities Impact on Confidentiality, Integrity, Availability`,
        gdpr_chart: `GDPR Impact Distribution (%)`,
        likelihood_chart: `Likelihood of Exploitation Based on Severity`,
        web_server_information: `Web Server Information`,
        ssl_security_tests: `SSL Security Tests`,
        alerts_title: `Vulnerability Report`,
      }
    },
    new_target: {
      new_dti_target: `Add new target Domain Threat Intelligence`,
      edit_dti_target: `Edit DTI target`,
      dti_description: `The Domain Threat Intelligence service allows you to identify the potential
                attack surfaces of the corporate domain. The activity is carried out through a process of
                search, identification and selection of publicly available information related to Domain,
                Subdomain and Compromised Emails. The service does not perform any security tests on the
                target and only operates on information available on the web or dark web. On the basis of
                the information collected at OSINT (Open Source Intelligence) and CLOSINT (Close Source
                    Intelligence) level the possible vulnerabilities of the target are identified.`,
      dti: `Threat Intelligence activity is carried out on targets and digital identifiers related to
                compromised assets and emails. We identify any public information available relating to a given
                target.`,
      new_nscan_target: `Add new Network Scan target`,
      edit_nscan_target: `Edit Network Scan target`,
      nscan: `The online Network Scanner service allows the scanning of infrastructure and devices to
                identify vulnerabilities and critical security issues.`,
      webapp: `The online WebApp Scan service is the automated service that scans for Web Vulnerabilities;
	            this service identifies security vulnerabilities and criticalities of websites and web applications.`,
      business_categories: "Business Category",
      target: "Target",
      extra: "Extra",
      vpn_optional: "Optional",
      vpn_recommended: "Recommended",
      vpn_not_recommended: "Not recommended",
      vpn_settings: "VPN Settings ({suggestion})",
      vpn_description: `If your server is behind VPN you can use this setting (VPN allows to send undeceive
                data across shared or public networks as if their computing devices were directly connected to the
                private network).`,
      has_vpn: `<strong>NOTE:</strong> You already configured previously the VPN settings.`,
      vpn_with_public: `Hai inserito una configurazione VPN con degli indirizzi pubblici. Sei sicuro di voler procedere?`,
      private_no_vpnc: `Hai inserito degli indirizzi privati senza una configurazione VPN. Sei sicuro di voler procedere?`,
      open_vpn: "OpenVPN OVPN",
      net_extender: "Sonicwall Net Extender",
      select_vpn: "Select the VPN",
      no_auth: "No VPN",
      user: "User",
      address: "Address",
      domain: "Domain",
      password: "Password",
      ovpn_file: "OVPN File",
      open_vpn_description: `OpenVPN is an open-source software application that implements virtual private
                network (VPN) techniques for creating secure point-to-point or site-to-site connections in routed
                or bridged configurations and remote access facilities. It uses a custom security protocol that
                utilizes SSL/TLS for key exchange. It is capable of traversing network address translators (NATs)
                and firewalls. We built a virtual appliance to easily test IPs into a LAN and you can read our
                documentation to configure at this link (<a href='https://www.swascan.com/it/tutorial-vpn/' target='_blank'>www.swascan.com/it/tutorial-vpn/</a>), but first you must download this package:`,
      virtual_appliance: "Virtual Appliance",
      windows_vmware_player: "VmWare Player for Windows",
      open_von_confg_files: "OpenVpn Configuration files",
      sonic_wall_description: `SonicWall has native SSL VPN built-in features. You can enable Tinexta Cyber
                platform and analyse the devices into your LAN easily and in a few steps.
				First step: you must configure your firewall in order to accept VPN connections and you can find a simple guide
                browsing Sonicwall Website (https://www.sonicwall.com/en-us/support/knowledge-base/170505401898786)
                Second step: you need to insert the information about:`,
      sonic_wall_user: "Username of the VPN User",
      sonic_wall_password: "Password of the VPN User",
      sonic_wall_domain: "Domain",
      sonic_wall_address: "Server address and port (example xxx.xxx.xxx.xxx:4433)",
      sonic_wall_tunneling: "The only requirement: Don’t configure the VPN Server to redirect the entire traffic into the tunnel.",
      sonic_wall_logo: "Sonic Wall Logo",
      open_vpn_config_file: "Configuration file for Open VPN:",
      only_ovpn_file: "* Only .ovpn file",
      open_vpn_credentials_file: "Credentials file for Open VPN (optional):",
      only_txt_file: "* Only .txt file",
      ip_address_not_valid: `IP, subnet, IP range or host name not valid`,
      ip_examples: `Examples: www.my-site.com, 192.168.1.1, 125.168.100.0/24, 10.0.0.1-10.0.0.15`,
      some_examples: "Examples:",
      insert_ip_range: {
        text: "To insert a range of IP addresses:",
        first_ip: "type the first IP address,",
        middle_hyphen: "type the - (middle hyphen) character",
        second_ip: "type the last IP address.",
        example_1: "10.0.0.1-10.0.0.15",
        example_2: "10.0.0.1-10.0.0.180",
      },
      insert_ip_with_subnet_mask: {
        text: "To insert an IP address with the Subnet Mask:",
        ip: "type the IP address,",
        slash: "type the / (slash) character,",
        subnet_mask: "type the subnet mask (from 20 to 32).",
        example_1: "125.168.100.0/24",
        example_2: "192.168.0.10/28",
      },
      unchanged: "Unchanged",
      new_cti_target: "Add newCyber Threat Intelligence",
      edit_cti_target: "Edit target CTI",
      cti_description: `The Cyber Threat Intelligence (CTI) activity focuses mainly on the analysis of "raw" data collected during specific events - recent and past - to monitor, detect and prevent Cyber Security threats to an organization. It does so by shifting the focus from reactive defence to preventive and "smart" security measures. Ideally, the CTI should become the basis on which a company builds its secure, alert and resilient defence perimeter.`,
      cti: `The Cyber Threat Intelligence (CTI) activity focuses mainly on the analysis of "raw" data collected during specific events - recent and past.`,
      tvm: `TVM monitors threat intelligence sources, the MITRE stream, news and industry blogs in order to intercept flaws and critical situations relevant to the selected technological context, providing support to the IT staff to assess the level od cyber risk exposure and set up mitigation plans.`,
      dti_lite: `Threat Intelligence activity is carried out on targets and digital identifiers related to compromised assets and emails. We identify any public information available relating to a given target, but only Executive Summary is show.`,
      extra_targets: `Extra targets (IP)`,
      new_webscan_target: `Add new WebApp Scan`,
      edit_webscan_target: `Edit WebApp Scan target`,
      rri: `The service gives an overview of the level of risk of ransomware attack. This is done by analysing potential 
            vulnerable areas of the company’s digital perimeter that could be attacked by criminal hackers.<br>
            The service - performed on a regular basis - provides an important proactive security indicator and driver 
            for subsequent comprehensive security testing activities.`,
      new_rri_target: `Add new Ransomware Risk Indicator`,
      edit_rri_target: `Edit Ransomware Risk Indicator target`,
    },
    search: {
      category: `Category`,
      thread_name: `Thread name`,
      posts: `Posts`,
      author: `Author`,
    },
    phishing_attack: {
      phishing_attack: "Phishing Attack Simulation",
      phishing_attack_description: `The Phishing Attack Simulation service allows you to
                set up an effective training and awareness activity for all your staff. This
                innovative solution ensures prevention in case of a phishing attack through
                real attack simulations.`,
      why_simulation: "Why a Phishing Attack Simulation?",
      reduce_risks: "Reduce the risk of Successful phishing attacks",
      reduce_costs: "Reduce security HR training costs",
      identify_phishing: "Educate employees to Identify phishing",
      compliance_standards: "Meet Compliance standards",
    },
    incident_response: {
      cyber_security_expert: "A Cyber Security Expert at your disposal!",
      incident_management: `The Tinexta Cyber Emergency Response Service to support its
                customers for IT incident management activities.`,
      incident_management_note: `Tinexta Cyber Service "Cyber Incident Response" incude 1 phone call of max 1h per license`,
      data_breach: "Have you undergone a Databreach?",
      data_breach_description: `Has your data been encrypted by a ransomware virus? Or have
                you undergone a Data Breach? The Tinexta Cyber Incident Response service will
                provide you with the indications and the steps to be implemented in order to
                restore the Company Business Continuity`,
      expert_available: "An expert at your disposal",
      expert_available_description: `A Cyber Security expert at your disposal to support you
                in the management of Incident Response Management in the event of a Data Breach. A
                staff of Cyber professionals available:`,
      h24: "H24",
      reporting: "Within 4 hours of reporting",
      consulting: "1 hour of counseling",
      service_name: "Cyber Incident Response Service",
      privacy: `The undersigned, as an interested party, DECLARES to have read and understood the content of 
                the <a href="https://www.swascan.com/it/informativa-sotto-attacco/" target="_blank">privacy policy</a> pursuant to 
                Article 13, GDPR. I AGREE to the processing of Data in relation to the sending by the Owner of communications 
                relating to the management of any pre-contractual measures, aimed at the stipulation and/or execution of the contract 
                with the Customer as well as the fulfillment of the related obligations.<br>
                The consent given may be revoked at any time by contacting the Data Controller at the contact details in the 
                aforementioned privacy policy.`,
      form: {
        task_name: `Title`,
        task_error: `* Title required`,
        name_error: "* Name required",
        surname_error: "* Surname required",
        url_error: "* URL required",
        company_error: "* Company required",
        email_error: "* Email required, incorrect format",
        incident_type_error: "* Incident type required",
        description_error: "* Description required",
        phone_error: "* Phone required",
      },
    },
    smishing_attack: {
      smishing_attack: "Smishing Attack Simulation",
      smishing_attack_description: `The Smishing Attack Simulation service is a
                fundamental part of any Cyber Security training programme. Thanks to
                its advanced simulation capabilities, it will be possible to perform
                real simulations of smishing attacks; crucial for employees' awareness
                against cyber threats.`,
      why_choose: "Why choose a Smishing Attack Simulation service?",
      reduce_risks: "To reduce the risks of successful Smishing attacks",
      identify_attacks: "To allow employees to spot and identify Smishing attacks",
      reduce_cost: "To reduce the cost of Cyber Security training",
      compliance_standards: "To meet compliance standards",
    },
    cyber_threat: {
      cyber_threat: "Cyber Threat Intelligence",
      cyber_threat_description: `The Cyber Threat Intelligence service gives you
                invaluable information about the level of risks your organization is
                exposed to. This early warning and preventive Cyber Security service
                include the collection and analysis of information in order to characterize
                possible cyber threats from a technical point of view in relation to specific
                operational contexts. Tinexta Cyber's Cyber Threat Intelligence service has the
                purpose and objective of identifying any public information available at OSINT
                and CLOSINT level relating to a specific target.`,
      thanks_cti: "Thanks to the CTI you can discover:",
      data_breach: "Data Breach & compromised emails",
      network_hygiene: "Network Hygiene related Threats",
      dark_web: "Dark Web activity",
      bot_net: "Botnet Activity &amp; Various Risks",
    },
    penetration_test: {
      penetration_test: "Penetration Test",
      penetration_test_description: `Penetration Testing activities consist of “examining”
                the weaknesses of a corporate IT infrastructure through an authorized (simulated)
                attack. Penetration testing represents the best practice to find out the weak spots
                of an organization from the Cyber Security point of view. The purpose is to highlight
                any vulnerabilities and security issues present, analyzing them through the results
                provided in the reports, and to indicate technological, organizational and procedural
                countermeasures able to raise the overall security state of the company.`,
      attack_simulation: "Simulation of a cyber attack",
      activity_executions: "Execution of both Black Box and White Box activities",
      critical_issues: "Vulnerability classification based on the severity level",
      pdf_reports: "Delivery of a final report in PDF format",
    },
    security_academy: {
      security_academy: "Cyber Academy",
      security_academy_description: `For organizations Information Security is an increasingly
                important issue that ensures the protection of corporate information systems against
                unauthorized access and data theft. Tinexta Cyber's Cyber Academy service provides
                specialized and tailor-made courses to all company employees, providing specialized
                trainings in different IT security areas. The courses are run by recognized industry
                professionals. The courses are delivered according to the specific needs with the aim
                of being in line with the technological context of the organization.`,
      packets: "Training packages of 8 hours",
      training: "Professional trainings for corporate employees",
      certificate: "Final participation certificate",
      courses: "Courses lead by experienced professionals",
    },
    cyber_incident_team: {
      cyber_incident_team: "Cyber Incident Team",
      cyber_incident_team_description: `Tinexta Cyber offers to organizations its Incident Response
                service through a highly specialized and dedicated Cyber Emergency Response Team for
                the management of Cyber Incidents, DDOS attacks, Data Breach and Ransomware attacks.
                The Team is composed of professionals with a long experience in CSIRT. Tinexta Cyber's Cyber
                Incident Response Team has been the first to identify and manage several next-generation
                Ransomware attacks and has received official recognition from international players for
                its Vulnerability Disclosure activities.`,
      critical_issues: "Identification of vulnerabilities and their severity levels",
      consulting: "1 hour of professional advisory",
      availability: "24/7 availability",
      first_aid: "Incident Response within 4 hours of reporting",
    },
    soc_as_service: {
      soc_as_service: "Soc as a Service",
      soc_as_service_description: `A Security Operations Center is a Cyber competence center that
                provides services aimed at governance, monitoring and management of corporate information
                systems. Its design, commissioning and maintenance can be costly and complex. Tinexta Cyber's SOC
                as a Service is the most effective, efficient, consistent and sustainable solution for business
                environments. With its Monitoring & Early Warning service, it allows you to identify, detect,
                analyze and report cyber attacks before they can turn into a real threat to your business.`,
      availability: "24/7 availability of the Security Analyst Team",
      reporting: "Monthly reporting of the activities carried out",
      activation: "Activated and operational in less than 48 hours",
      subscription_service: "Subscription service",
    },
    code_review: {
      title: `Code Review`,
      title2: `What you get with the Code Review service:`,
      description: `The Code Review service is a process that – through the source code analysis of applications – verifies the presence and effectiveness of minimum security standards. Code verification is useful to be sure that the target application has been developed in order to "auto-defend" itself in its own environment. It spots and corrects errors that developers did not notice in the developing phase. It improves the overall quality of the software and developers’ skills. `,
      bullets: {
        bullet1: `Detailed source code analysis`,
        bullet2: `Identification of vulnerabilities and critical areas`,
        bullet3: `Compliance test with GDPR`,
        bullet4: `Automatic generation of PDF and CSV reports`,
      },
    },
    osint_search: {
      title: `OSINT Search`,
      request_description: `To activate this service contact our team`,
    },
    subdomains: "Sottodomini",
    vulnerability_early_warning: {
      title: "Technology Vulnerability Monitoring",
      activate_product: "Activate notifications",
      service_description: `The service is designed as a dedicated solution, ideal for proactive
                risk management of technological vulnerabilities. The service monitors threat intelligence
                sources, the MITRE stream, news and industry blogs in order to intercept flaws and critical
                situations relevant to the selected technological context, providing support to the IT staff
                to assess the level od cyber risk exposure and set up mitigation plans.`,
      email_label: "I would like to receive the results by email:",
      select_vendors_and_products: "Select Vendors and Product",
      select_vendor: "Select vendor:",
      select_product: "Select product:",
      add: "Add",
      no_products_selected: "No products selected",
      limit_reached: "You have reached the maximum limit of products",
      vendor: "Vendor",
      product: "Product",
      email_error: "* Email required, incorrect format",
      saved: "Products saved",
      delete: "Delete",
      product_warning: "Product not present",
      vendor_warning: "Vendor not present",
      product_placeholder: "Select a product",
      vendor_placeholder: "Select a vendor",
      no_filter: "Use search to filter vendors.",
      remove_all: "Remove subscriptions",
      remove_message: `You have right to opt-out and delete all data by yourself by pressing the cancellation key or deleting e-mails and / or products`,
    },
    tvm: {
      tvm: "Technology Vulnerability Monitoring",
      tvm_description:
        "The service is conceived as a dedicated solution, useful for proactive risk management related to technological vulnerabilities. Technology Vulnerability Monitoring controls threat intelligence sources, the MITER flow, news and cybersec blogs to intercept leaks and criticalities related to the selected technologies, providing support to the IT staff to assess the IT risk exposure and set up remediation plans.",
      product_list: "What you get with the Technology Vulnerability Monitoring service",
      icon_1: "Identify vulnerabilities and issues",
      icon_2: "Proactive maintenance of security",
      icon_3: "Prompt alert",
    },
    security_measures: {
      title: `Security Measures`,
      description: `Tinexta Cyber, in order to make its customers aware of the security of their personal data processed through the proprietary platform in the cloud, provides as follows:`,
      bullet_1: `The organization has provided specific professional figures within its staff dedicated to the secure development acivities of the platform and the maintenance of security measures related to the cloud environment.`,
      bullet_2: `Tinexta Cyber will not make any spontaneous disclosure of personal data granted by its customers. If a national authority requests your personal data, Tinexta Cyber will try to steer the Authority’s inquiry towards you. It should be noted that all requests forwarded by an Authority to Tinexta Cyber regarding the personal data of its customers must be sent in compliance with the relevant Laws and Regulations, otherwise Tinexta Cyber will not disclose any content. Finally, if an Authority requests and obtains, by virtue of the foregoing, personal data, Tinexta Cyber undertakes to inform the interested party / subject of the request in order to allow them to obtain information from aforesaid Authorities and / or obtain precautionary measures, if Tinexta Cyber is not prevented from doing so according to applicable Laws and / or regulations. In order to monitor all requests coming from the Authority, Tinexta Cyber holds a special register which contains the data transmitted and the requesting / receiving Authority.`,
      bullet_3: `The personal data processed in relation to the execution of the cloud service will be stored at the Amazon Web Services data centers, located in Frankfurt, Germany, within the European Economic Area.`,
      bullet_4: `Personal data will be stored and subsequently deleted in accordance with the provisions of documents PR_01 Data retention policy.`,
      bullet_5: `Tinexta Cyber offers its partners and customers a set of tools through which to protect their data and those of customers from the many attack vectors present. In order to ensure an adequate level of protection of information and personal data processed in the context of its activities, which includes security controls such as:`,
      bullet_5_a: `encryption of data stored on the storage of the Cloud Provider;`,
      bullet_5_b: `encryption of data passing through;`,
      bullet_5_c: `WAF (Web Application Firewall) aimed at mitigating attacks (DDoS and others);`,
      bullet_5_d: `Periodic independent platform security checks (VA / PT);`,
      bullet_5_e: `Scrupulous attention to procedures aimed at guaranteeing continuity of service.`,
      bullet_5_end: `Finally, by using the services provided through Amazon web Services, it monitors the use of accesses with the aid of Aws Cloud Trail, it is specified that Tinexta Cyber cannot reduce the aforementioned security measures unilaterally.`,
      bullet_6: `Tinexta Cyber provides periodic backup of customer data (in addition to those necessary for the operation of its services) at least daily, keeping copies of the last 30 days, to guarantee continuity of service for the availability of data access. The data are kept encrypted, and access is restricted to a qualified subset of Tinexta Cyber technical personnel.`,
      footer: `Together with these controls, Tinexta Cyber is certified in compliance with the ISO / IEC 27001 standard, which guarantees on the indicated procedures (and others) aimed at the security and confidentiality of customer information and data.`,
    },
    elearning_academy: {
      elearning_academy: "Cyber e-Learning Platform",
      elearning_academy_description: `The objective of the e-Learning Platform is to act on the awareness of each 
                      employee and on their ability to recognize potential threats and consequently adopt correct 
                      behaviors to strengthen the security level of organizations.<br><br>

                      The e-learning courses train all company actors, updating them on potential cyber threats, on the 
                      appropriate and proactive behaviors to be adopted in order to avoid computer fraud, phishing 
                      attacks and much more.<br><br>
                      
                      Furthermore, our courses allow companies to be compliant with current laws and the correct 
                      management of personal data.`,
      bullet_1: "Training material continuously updated",
      bullet_2: "Attractive User Experience",
      bullet_3: "Remote learning",
      bullet_4: "Simple and informative language",
      bullet_5: "Final Evaluation Test",
      bullet_6: "Attendance Certificate",
    },
    rri: {
      description: `The service gives an overview of the level of risk of ransomware attack. This is done by analysing 
                    potential vulnerable areas of the company’s digital perimeter that could be attacked by criminal hackers.<br>
                    The service - performed on a regular basis - provides an important proactive security indicator 
                    and driver for subsequent comprehensive security testing activities.`,
      icon_1: `Analyze Ransomware Risk`,
      icon_2: `Proactive maintenance of security`,
      icon_3: `Simple and Clear Alert Indicator`,
    },
    cloud_security: {
      title: "Cloud Services",
      description: `A Cyber Competence Centre dedicated to the world of Cloud for governance, support and 
                    protection of the entire set of technologies, protocols and best practices 
                    of cloud computing environments.`,
      benefits: `Cosa ottieni con il servizio Cloud Services`,
      icon_1: `CSA Framework Assessement`,
      icon_2: `Cloud Environment Security Assessment (CESA)`,
      icon_3: `SaaS Supply Chain Assessment`,
      icon_4: `Azure Active Directory Assessment (AADA)`,
    },
    webscan: {
      title: "WebApp Scan",
      description: `WebApp Scan is automated Web Vulnerability Scan. Vulnerability Assessment Scanner Tool can identify 
                    vulnerabilities and security criticalities for websites and web applications. Vulnerability analysis 
                    aims to quantify the risk levels and indicate the corrective and repositioning actions necessary for recovery.`,
      icon_1: `Most Critical risks are indicated`,
      icon_2: `Suggestions for corrections`,
      icon_3: `Details for a better comprehension`,
      icon_4: `Offline Reports (downloadable)`,
      alerts_title: `Vulnerabilities Report`,
    },
    card_protection: {
      title: "Card Protection",
      description: `Sorry, you have not activated our "Card Protection" service. This service offers a number of essential 
              benefits for the security of your payment cards, including immediate notifications of breaches and a detailed monthly 
              report on your card activity. To activate the service and benefit from these advanced security features, we invite 
              you to contact us using the button below.`,
      service_description: `The Card Protection service offers an effective solution for the security of your payment cards. 
              With this service, you will receive timely notifications of Breach, as well as a detailed monthly report. 
              To activate the service, all you need to do is enter your payment card number and choose an alias to uniquely identify it. 
              It is important to note that the CARD PROTECTION system guarantees maximum security of your data: the card number will not 
              be stored in clear format, and will be encrypted using the SHA-256 algorithm ensuring advanced protection for your 
              financial information.`,
      disclaimer: `The card number is not stored in clear text, but is encrypted with SHA-256;<br>only the last 4 digits will be retained`,
      product_list: "What you get with the Card Protection:",
      icon_1: "Proactive maintenance of security",
      icon_2: "Prompt alert",
      remove_all: `Remove all`,
      email_label: "I would like to receive the results by email:",
      email_error: "* Email required, incorrect format",
      remove_message: `You have right to opt-out and delete all data by yourself by pressing the cancellation key`,
      add_card: `Add a Credit Card`,
      cards_add: `Add new card`,
      cards_del: `Remove card`,
      last_4_digits: `Card Number`,
      card_number: `Card number`,
      alias: `Card Label`,
      no_cards: `No cards found. Start adding cards!`,
      card_added: `Card has been successfully saved!`,
      card_removed: `Card has been successfully removed!`,
      recipients: `Update recipients`,
      recipients_updated: `Recipients has been successfully updated!`,
    },
  },
  menu: {
    dashboard: `Dashboard`,
    my_sites: `My sites`,
    dti: `Domain Threat Intelligence`,
    webapp: `Web App Scan`,
    nscan: `Network Scan`,
    code_review: `Code Review`,
    vulnerability_early_warning: "Technology Monitoring",
    gdpr: `GDPR Assessment`,
    ict: `ICT Assessment`,
    survey_gdpr: `GDPR Assessment`,
    survey_ict: `ICT Assessment`,
    survey_test: `TEST Assessment`,
    phishing_attack: `Phishing Attack`,
    incident_response: `Incident Response`,
    smishing_attack: `Smishing Attack`,
    cyber_threat: `Cyber Threat Intelligence`,
    available_licenses: `Available Services`,
    active_licenses: `Active Services`,
    main: `Dashboard`,
    targets_list: `Targets`,
    reports_list: `Reports`,
    threat_intelligence: "Threat Intelligence",
    technology_risk: "Technology Risk",
    penetration_test: "Penetration Test",
    human_risk: "Human Risk",
    security_academy: "Cyber Academy",
    risk_management: "Risk Management",
    data_breach_mgmt: "Data Breach Management",
    cyber_incident_team: "Cyber Incident Team",
    soc_as_service: "Soc As a Service",
    osint_search: `OSINT Search`,
    dti_lite: `Domain Threat (Lite)`,
    contact_us: `Ticket Opening`,
    elearning_academy: "Cyber e-Learning Platform",
    rri: "Ransomware Risk Indicator",
    cloud: "Cloud",
    cloud_security: "Cloud Security",
    card_protection: "Card Protection",
  },
  footer: {
    terms_and_conditions: `Terms and conditions`,
    terms_and_conditions_link: `https://www.swascan.com/terms-conditions-2/`,
  },
  statuses: {
    SUCCESS: `Success`,
    FAILURE: `Failed`,
    RUNNING: `Running`,
    PENDING: `Pending`,
    DELETED: `Deleted`,
    STOPPED: `Stopped`,
    STOPPING: `Stop requested`,
  },
  business_categories: {
    Agriculture: "Agriculture",
    "Basic Metal Production": "Basic Metal Production",
    "Chemical industries": "Chemical industries",
    Clothing: "Clothing",
    Commerce: "Commerce",
    Construction: "Construction",
    Culture: "Culture",
    Drink: "Drink",
    Education: "Education",
    "Financial services": "Financial services",
    Food: "Food",
    Footwear: "Footwear",
    Forestry: "Forestry",
    "Health services": "Health services",
    Hotels: "Hotels",
    "Mechanical and electrical engineering": "Mechanical and electrical engineering",
    Media: "Media",
    "Oil and gas production": "Oil and gas production",
    "Postal and telecommunications services": "Postal and telecommunications services",
    "Professional services": "Professional services",
    "Public service": "Public service",
    Shipping: "Shipping",
    Textiles: "Textiles",
    Tourism: "Tourism",
    Transport: "Transport",
    "Utilities (water; gas; electricity)": "Utilities (water; gas; electricity)",
  },
  errors: {
    no_remaining_scan: `No remaining scan.<br>Contact us to upgrade!`,
    wrong_targets_format: `Target is not valid`,
    ir_form_error: `Something went wrong while sending the form!`,
  },
  about: {
    system_title: "Application version",
    platform: "Piattaforma",
    engines: {
      dti: "DTI Engine",
      nscan: "Network Scan Engine",
      nmap: "Nmap Engine",
      vpn_manager: "VPN Manager",
      gvm: "GVM Engine",
      cti: "CTI Engine",
    },
  },
  login: {
    reset_password: "Reset Password",
    no_account: "Do not have an account?",
    get_started: "Get started now. It's fast and easy!",
    register: "Register",
    sign_in: "Sign in",
    credentials_error: "Wrong credentials",
  },
  breaches: {
    source: {
      actor_name: "Actor name",
      category: "Category",
      geography: "Geography",
      attack_vector: "Attack vector",
      compromised_data: "Compromised Data",
    },
  },
  contact_form: {
    subtitle: `Please fill in the form below to ask for assistance if you have noticed any faults with the services:`,
    title: `Ticket Category`,
    engine: `Select the service for which you found the anomaly`,
    type: `Select the area where the problem occurred`,
    report_object: `Select the Target/Report for which you need assistance`,
    notes: `Please describe the issue`,
    privacy: `The undersigned, as data subject, hereby DECLARES that he has read and fully understood the content of the <a href="https://www.swascan.com/privacy-policy/" target="_blank">privacy policy</a> on the processing of data pursuant to the Article 13, GDPR and expressly grants Tinexta Cyber the authority to process my personal data for the above service, based on the privacy policy and the legal basis for processing.`,
    send: `Invia segnalazione`,
    processing: `Invio in corso...`,
    title_failed_scan: `Failed Scan`,
    title_report: `Report not available for download`,
    title_license: `License not valid`,
    title_generic_error: `License not valid`,
    title_other: `Other`,
  },
  ransomware_attack_index: {
    description: `The Ransomware Attack Index indicates Ransomware risk exposition, considering data gathered and 
                  analyzed in last 30 days with relation of indicated internet domain of company.<br><br>
                  This index is based on:`,
    bullet1: `Analysis of number of exposed services;`,
    bullet2: `Analysis of data leak (e.g. company email and password stolen to employees);`,
    bullet3: `Analysis of potential vulnerabilities that can be used by attacker.`,
    footer: `These three dimensions synthetize possible attack surface exploitable by cybercriminals.`,
  },
  survey: {
    errors: {
      answer_question_mismatch: (context) => `Answer id ${context.named("answer_id")} does not match question id ${context.named("question")}`,
      answer_should_be_integer: (context) => `Invalid answer ${context.named("answer_id")}: value should be integer for this question`,
      answer_note_not_required: (context) => `Invalid answer ${context.named("answer_id")}: note field is not required`,
      answer_too_few_answers: (context) => `Invalid answer ${context.named("question_id")}: too few answers ${context.named("values_count")}. Minimum is ${context.named("minimum")}`,
      answer_too_many_answers: (context) => `Invalid answer ${context.named("question_id")}: too many answers ${context.named("values_count")}. Maximum is ${context.named("minimum")}`,
      answer_missing: (context) => `Invalid answer ${context.named("question_id")}: answer not in response`,
      answer_only_one_allowed: (context) => `Invalid answer ${context.named("question_id")}: there should be one and only one answer`,
      answer_value_lower_than_minimum: (context) => `Invalid answer ${context.named("question_id")}: value ${context.named("value")} lower than minimum value ${context.named("minimum")}`,
      answer_value_greater_than_maximum: (context) => `Invalid answer ${context.named("question_id")}: value ${context.named("value")} greater than maximum value ${context.named("maximum")}`,
      question_malformed: (context) => `Malformed question ${context.named("question_id")}: min > max`,
      question_min_max_integer: (context) => `Malformed question ${context.named("question_id")}: min and max should be integer for list`,
      question_default_should_be_integer: (context) => `Malformed question ${context.named("question_id")}: default should be integer for this question`,
      question_responses_only_for_lists: (context) => `Malformed question ${context.named("question_id")}: responses parameter can be used only for lists`,
      question_too_many_responnses: (context) => `Malformed question ${context.named("question_id")}: maximum responses greater than responses number`,
      question_note_must_be_true: (context) => `Malformed question ${context.named("question_id")}: with minimum and maximum equal to 0 note must be true`,
      question_threshold_only_int_float: (context) => `Malformed question ${context.named("question_id")}: threshold parameter can be used only for integer and float`,
      question_first_threshold_lower_than_min: (context) => `Malformed question ${context.named("question_id")}: first threshold lower than minimum value ${context.named("minimum")}`,
      question_last_threshold_greater_than_max: (context) => `Malformed question ${context.named("question_id")}: last threshold greater than maximum value ${context.named("maximum")}`,
      question_thresholds_texts_mismatch: (context) => `Suggestion Condition: Thresholds and texts number mismatch`,
    }
  },
  surveys: {
    title: `Surveys`,
    form: {
      title: `New Survey: {engine}`,
      task_name: `Survey Name`,
      company: `Company`,
      business_name: `Business Name`,
      office: `Address`,
      email: `Email`,
      save_and_close: `Save & Exit`,
    },
    report: {
      details: `Details`,
      survey: `Survey`,
      score: `Score`,
      summary: `Executive Summary`,
      level_of_gdpr_compliance: `Level of GDPR Compliance`,
      data_processing: `Data Processing`,
      data_management: `Data Management`,
      privacy_notice: `Privacy Notice`,
      rights_of_the_data_subject: `Rights of the Data Subject`,
      governance: `Governance`,
      data_protection: `Data Protection`,
      data_protection_impact_assessment: `Data Protection Impact Assessment`,
      privacy_by_design: `Privacy by Design`,
      question_list: `Question List`,
      question: `Domanda`,
      question_detail: `Question Detail`,
      category: `Category`,
      description: `Description`,
      solution: `Solution`,
      references: `references`,
      action: `Action`,
      functional_cyber_risk: `Functional Cyber Risk`,
      technology_risk_management: `Technology Risk Management`,
      data_risk_management: `Data Risk Management`,
      governance_risk_management: `Governance Risk Management`,
      human_risk_management: `Human Risk Management`,
      technology_risk: `Technology Risk`,
      data_risk: `Data Risk`,
      governance_risk: `Governance Risk`,
      human_risk: `Human Risk`,
      operational_cyber_risk: `Operational Cyber Risk`,
      preventive_security: `<strong>Preventive Security</strong><br>Gap Risk Level`,
      data_break: `<strong>Data Breach</strong><br>Exposure Level`,
      proactive_security: `<strong>Proactive Security</strong><br>Gap Risk Level`,
      ransomware_recovery: `<strong>Ransomware Recovery</strong><br>Exposure Capability`,
      preventive_security_gap_risk: `Preventive Security Gap Risk`,
      proactive_security_gap_risk: `Proactive Security Gap Risk`,
      data_breach_exposure: `Data Break Exposure`,
      ransomware_recovery_exposure: `Ransomware Recovery Exposure`,
    },
    executive_summary: {
      global_risk: `Global Risk`,
      functional_risk: `Functional Risk`,
      operational_risk: `Operational Risk`,
      cyber_risk_assessment_description: `The Cyber Risk Functional and Operational are the indicators identified by the 
          ICT Security Assessment service. The ICT Security Assessment is a proprietary methodology of Tinexta Cyber that allows 
          companies to verify and measure their level of cyber risk and assess the effectiveness of the security measures adopted. 
          The ICT Security Assessment service provides guidance and corrective actions to be taken at the level of Organization, 
          Policy, Personnel, Technology, and Control Systems.`,
      preventive_security: `Preventive Security`,
      data_breach: `Data Breach`,
      proactive_security: `Proactive Security`,
      ransomware_recovery: `Ransomware Recovery`,
    },
    functional_cyber_risk: {
      description: `The Cyber Risk Functional determines the level of cyber risk for the functional assets of 
          the Corporate Cyber Security Framework:`,
      html: `<h3 class="sub-title">Technology Risk Management</h3>
          <p class="sub-text">Based on the tools and solutions adopted, it determines the level of technological risk.</p>
          <h3 class="sub-title">Data Risk Management</h3>
          <p class="sub-text">It measures the level of exposure to risk of company data in terms of business and data protection.</p>
          <h3 class="sub-title">Governance Risk Management</h3>
          <p class="sub-text">It identifies the level of governance risk through an assessment of the adopted policies and procedures.</p>
          <h3 class="sub-title">Human Risk Management</h3>
          <p class="sub-text">It determines the level of human risk based on the awareness and understanding of employees regarding potential cybersecurity threats.</p>`,
    },
    operational_cyber_risk: {
      description: `Operational Cyber Risk measures and determines the risk of experiencing losses due to the inadequacy or 
          dysfunction of functional assets. Operational risk determines the risk of experiencing cyber attacks or 
          incidents based on the effectiveness and efficiency of preventive and proactive security measures. At the same time, 
          it identifies the likelihood of experiencing a Data Breach and the ability to recover from a ransomware attack.`,
      html: `<h3 class="sub-title">Preventive Security</h3>
          <p class="sub-text">It accurately determines the level of risk and vulnerability of the company based on the preventive cybersecurity measures employed in this area.</p>
          <h3 class="sub-title">Data Breach</h3>
          <p class="sub-text">It measures the level of exposure to potential data breaches within the company.</p>
          <h3 class="sub-title">Proactive Security</h3>
          <p class="sub-text">It accurately determines the level of risk and vulnerability of the company based on the proactive cybersecurity measures employed in this area.</p>
          <h3 class="sub-title">Ransomware Recovery</h3>
          <p class="sub-text">It identifies the risk of data recovery and business continuity following a ransomware attack.</p>`,
    },
    add_new: `New Survey`,
    survey_gdpr: {
      title: `At the moment you cannot use the GDPR measures self-assessment service.`,
      description: `To be able to complete the assessment and obtain the report indicating your GDPR compliance and corrective actions, contact us by pressing the button below`,
      icon_1: `Fill out the questionnaire by answering the simple questions below`,
      icon_2: `A PDF Report will show the areas to improve with the relevant indications.`,
    },
    survey_ict: {
      title: `At the moment you cannot use the self-assessment service of security measures.`,
      description: `To be able to complete the assessment and obtain the report indicating your safety posture according to the main ISO 27001 and FNCS indications, contact us by pressing the button below`,
      icon_1: `Fill out the questionnaire by answering the simple questions below`,
      icon_2: `A PDF Report will show the areas to improve with the relevant indications.`,
    },
  }
};
